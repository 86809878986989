@import '@hiredigital/ui/variables';

.loginActionsContainer {
  margin-top: auto;

  @include for-size(phone-only) {
    display: grid;
    grid-template-columns: 100%;
    width: 100%;
  }
}

.contentWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: auto;
  max-width: 1200px;
  // min-height: 100vh;
  z-index: $z-base;

  @include for-size(phone-only) {
    width: 100%;
    margin: auto;
  }
}

.introContainer {
  display: flex;

  @include for-size(tablet-landscape-up) {
    flex-basis: 50%;
    align-items: center;
    justify-content: start;
  }

  @include for-size(phone-only) {
    display: none;
  }
}

.introImage {
  width: 100%;
  max-width: 600px;
}

.introText {
  font-size: 36px;
  font-weight: $fw-1;
  color: $white;
  line-height: 1.2;

  @include for-size(tablet-landscape-up) {
    font-size: 48px;
  }
}

.formContainer {
  max-width: 500px;
  // padding: 100px 0px;
  margin: 20px 0px 20px 0px;
  z-index: 1;

  @include for-size(tablet-landscape-up) {
    flex-basis: 60%;
    align-items: center;
  }

  @include for-size(phone-only) {
    padding: 0;
  }
}

.logoContainer {
  width: 100%;
  display: flex;
}

.logo {
  margin: auto;
  height: 40px;
  width: 200px;
  // padding: 20px;
}

.card {
  width: 100%;
  padding: 30px;
  margin: 40px 0;
  box-sizing: border-box;
  background: $white;
  border-radius: 15px;
  box-shadow: $shadow-50;

  @include for-size(phone-only) {
    margin: 20px;
    padding: 20px;
    width: auto;
  }
}

.signup {
  color: $white;
  font-size: $size-3;
  line-height: $line-height-3;
  padding: 0 25px;
  text-align: center;
  display: flex;
  justify-content: center;
}

.signupLink {
  color: $white;
  font-size: $size-3;
  line-height: $line-height-3;
  font-weight: $fw-2;

  &:hover {
    color: $yellow-100;
  }
}

.header {
  margin-bottom: 40px;
}

.headline {
  color: $black;
  line-height: $line-height-6;
  font-size: $size-6;
  font-weight: $fw-2;
  margin-bottom: 16px;

  @include for-size(phone-only) {
    font-size: $size-5;
    line-height: $line-height-5;
  }
}

.description {
  font-size: $size-4;
  font-weight: $fw-1;
  line-height: $line-height-4;
  margin-bottom: 16px;

  @include for-size(phone-only) {
    font-size: $size-3;
    line-height: $line-height-3;
  }
}

.inputContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 16px;
}

.forgotPwd {
  margin-top: 8px;
  color: $blue-100;
  text-decoration: none;
  font-size: $size-3;
  line-height: $line-height-3;
  font-weight: $fw-2;

  &:hover {
    color: $blue-120;
  }
}

.confirmActionContainer {
  margin-top: auto;

  @include for-size(phone-only) {
    width: 100%;
  }
}

.confirmMessage {
  border-radius: 3px;
  padding: 10px;
  margin: 5px 0;
  margin-bottom: 20px;
  background-color: $green-10;
  // color: $green-100;
}

.errorMessage {
  border-radius: 3px;
  padding: 10px;
  margin: 5px 0;
  margin-bottom: 20px;
  background-color: $red-10;
  color: $red-100;
}

.loginButton {
}

.bottom {
  position: absolute;
  bottom: 20px;
  width: 100%;
  @include for-size(phone-only) {
    display: none;
  }
}
