@import '../variables.scss';

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: $btn-padding-3;
  font-family: $cerebri;
  min-width: 75px;
  border-radius: 8px;
  box-sizing: border-box;
  border: 2px solid transparent;
  cursor: pointer;
  vertical-align: middle;
  text-align: center;
  user-select: none;
  white-space: nowrap;
  font-weight: $fw-2;
  font-size: $btn-font-3;
  text-decoration: none;
  overflow: hidden;
  line-height: $btn-font-3;
  transition: background-color 0.4s $cubic, box-shadow 0.3s ease-in-out, width 0.4s $cubic;
  will-change: background;
  word-wrap: break-word;
  outline: none;
  background-color: transparent;

  &.isLoadingBtn {
    color: transparent;
  }

  & ~ & {
    margin-left: 10px;
  }

  &[disabled] {
    cursor: default;
    opacity: 0.5;
  }
  // @include ie-only {
  //   // apply these styles on ie only
  //   height: 42px; // fix on flex child not centering vertically when no height is provided
  // }

  &:focus {
    transition: none;
    outline: none;
    box-shadow: 0 0 0 3px $blue-10;
  }
}

div.loader {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -4px;
}

.blue {
  background-color: $blue-100;
  color: $white;

  &:hover {
    background-color: darken($blue-100, 10%);
  }
  &:active {
    background-color: darken($blue-100, 20%);
  }
}

.darkblue {
  background-color: $blue-120;
  color: $white;

  &:hover {
    background-color: darken($blue-120, 10%);
  }
  &:active {
    background-color: darken($blue-120, 20%);
  }
}

.lightGray {
  background-color: $gray-7;
  color: $gray-80;

  &:hover {
    background-color: darken($gray-7, 10%);
  }

  &:active {
    background-color: darken($gray-7, 20%);
  }
}

.gray {
  background-color: $gray-80;
  color: $white;

  &:hover {
    background-color: darken($gray-80, 10%);
  }
  &:active {
    background-color: darken($gray-80, 20%);
  }
}

.white {
  background-color: $white;
  color: $blue-120;

  &:hover {
    background-color: $blue-5;
    color: $blue-120;
  }

  &:active {
    background-color: $blue-7;
    color: $blue-120;
  }
}

.blueOutline {
  color: $blue-100;
  border-color: $blue-100;
  background: transparent;

  &:hover {
    border-color: darken($blue-100, 10%);
    color: darken($blue-100, 10%);
  }

  &:active {
    border-color: darken($blue-100, 20%);
    color: darken($blue-100, 20%);
  }
}

.whiteOutline {
  color: $white;
  border-color: $white;
  background: transparent;

  &:hover {
    border-color: darken($white, 10%);
    color: darken($white, 10%);
  }

  &:active {
    border-color: darken($white, 20%);
    color: darken($white, 20%);
  }
}
.redOutline {
  color: $red-50;
  border-color: $red-50;
  background: transparent;

  &:hover {
    border-color: darken($red-50, 10%);
    color: darken($red-50, 10%);
  }

  &:active {
    border-color: darken($red-50, 20%);
    color: darken($red-50, 20%);
  }
}

.whiteBlueOutline {
  color: $blue-100;
  border-color: $white;
  background-color: $white;
  border-color: $blue-100;
  word-break: break-word;
  white-space: normal;

  &:hover {
    background-color: $blue-5;
    border-color: darken($blue-100, 10%);
    color: darken($blue-100, 10%);
  }

  &:active {
    background-color: $blue-5;
    border-color: darken($blue-100, 20%);
    color: darken($blue-100, 20%);
  }
}

.fullwidth {
  width: 100%;
  margin: 6px 0px;
}
.halfwidth {
  width: 50%;
  margin: 6px 0px;
}

.large {
  height: 56px;
}

.fullwidthLarge {
  width: 100%;
  margin: 6px 0px;
  height: 56px;
}

.menuButtonContainer {
  display: inline-flex;
}

.moreIcon {
  cursor: pointer;
  height: 18px;
  width: auto;
}

.splitBtnContainer {
  display: inline-flex;
  min-height: 44px;
  width: auto;
}

.mainBtn {
  margin-right: 0;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  padding-right: 6px;
  border-right: 0 !important;
}

.moreBtn {
  margin-left: 0 !important;
  min-width: 20px !important;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  padding: 0 5px;
  height: 100%;

  &.default {
    background-color: none;
    height: auto;
    padding: 5px 0;
    border-radius: $border-radius;
    margin: auto;

    &:hover {
      background-color: $gray-10;
    }
  }
}

.dropdownContainer {
  padding: 10px 0;
}

.dropdownItem {
  display: flex;
  padding: 5px 25px;
  cursor: pointer;
  text-decoration: none;
  color: $black;
  width: 100%;
  font-size: $size-3;
  font-weight: normal;

  &:hover {
    background-color: $gray-7;
    text-decoration: none;
    color: $black;
  }
}
